<template>
    <div class="page-wrapper">
        <section class="hero_block form_hero">
            <div class="data">
                <h1 class="title"><span v-html="contentIsLoaded ? content.entete2.titre : ''"></span></h1>
                <div class="hours">
                    <svg fill="none" viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
                        <g>
                            <path
                                d="m14.8887 16.3611c.4063.4073 1.0664.4073 1.4727 0 .4072-.4062.4072-1.0663 0-1.4726l-2.8193-2.8203v-4.7762c0-.5752-.4668-1.042-1.042-1.042s-1.042.4668-1.042 1.042v5.208c0 .2764.1104.541.3057.7363z"
                            />
                            <path
                                clip-rule="evenodd"
                                d="m12.5 0c-6.90325 0-12.5 5.59675-12.5 12.5 0 6.9032 5.59675 12.5 12.5 12.5 6.9032 0 12.5-5.5968 12.5-12.5 0-6.90325-5.5968-12.5-12.5-12.5zm-10.417 12.5c0-5.753 4.664-10.417 10.417-10.417s10.417 4.664 10.417 10.417-4.664 10.417-10.417 10.417-10.417-4.664-10.417-10.417z"
                                fill-rule="evenodd"
                            />
                        </g>
                    </svg>
                    <p class="regular-text"><span v-html="globalsIsLoaded ? globals.header.titre1 : ''"></span></p>
                </div>
                <p class="regular-text">
                    <span v-html="contentIsLoaded ? content.entete2.texte : ''"></span>
                </p>
                <a :href="'tel:' + ((globalsIsLoaded) ? globals.header.titre2 : '')" class="cta regular-text">
                    <span v-html="(globalsIsLoaded) ? globals.header.titre2 : ''"></span>
                </a>
                <img src="@/assets/img/texture.svg" alt="texture" class="texture" />
            </div>
            <div class="hero_img contact">
                <h2 class="title small">Formulaire de préadmission</h2>

                <form
                    action=""
                    class="form"
                    name="aide"
                    method="post"
                    accept-charset="UTF-8"
                    @submit="sendForm($event)"
                >
                    <input type="text" placeholder="Nom et prénom *" class="input" name="name" v-model="formData.nom" required />
                    <input type="email" placeholder="Courriel *" class="input" name="email" v-model="formData.courriel" required />
                    <input type="text" placeholder="Téléphone *" class="input" name="telephone" v-model="formData.telephone" required />

                    <div class="radio mr">
                        <p class="regular-text">Centre pour homme</p>
                        <input type="radio" name="centre" v-model="formData.centre" value="Centre pour homme" />
                    </div>
                    <div class="radio">
                        <p class="regular-text">Centre pour femme</p>
                        <input type="radio" name="centre" v-model="formData.centre" value="Centre pour femme" />
                    </div>

                    <textarea placeholder="Message *" class="input textarea" name="message" v-model="formData.message" required></textarea>

                    <input type="submit" value="Envoyer" class="submit" />
                </form>

            </div>
            <img src="@/assets/img/birds-hero.svg" alt="birds" class="birds_hero" />
        </section>
    </div>
</template>

<script>
// @ is an alias to /src
import { mapState, mapGetters } from 'vuex'

import axios from 'axios'
import Swal from 'sweetalert2'
import 'sweetalert2/src/sweetalert2.scss'

export default {
    name: 'Aide',

    data() {
        return {
            // teamCarousel: null,
            isSending: false,
            formMessage: '',
            formData: {
                nom: '',
                courriel: '',
                telephone: '',
                centre: 'Centre pour homme',
                message: ''
            },
        }
    },

    methods: {
        async sendForm(e) {
            e.preventDefault()

            if (this.isSending) {
                return
            }

            this.isSending = true

            this.formData['formName'] = 'Aide'
            this.formData['sujet'] = 'Aide'

            const formData = new FormData()
            formData.append('formName', this.formData.formName)
            formData.append('sujet', this.formData.sujet)
            formData.append('centre', this.formData.centre)
            formData.append('courriel', this.formData.courriel)
            formData.append('message', this.formData.message)
            formData.append('nom', this.formData.nom)
            formData.append('telephone', this.formData.telephone)

            const config = {
                headers: {
                    'Content-Type': 'multipart/form-data',
                },
            }

            await axios
                .post(
                    `${process.env.VUE_APP_SITE_BASE_URL}/api/send/contact-form`,
                    formData,
                    config
                )
                .then(res => {
                    this.formMessage = 'success'
                    this.formMessageStatus = res.status

                    Swal.fire({
                        text: 'Votre message a été envoyé avec succès.',
                        icon: 'success',
                        toast: true,
                        position: 'top-end',
                        showConfirmButton: false,
                        timer: 3000,
                        timerProgressBar: true,
                    })

                    // this.hideform()
                    this.formData = {}
                    this.formMessage = ''

                    setTimeout(() => {
                        this.isSending = false
                    }, 3000)
                })
                .catch(e => {
                    this.formMessage = e

                    setTimeout(() => {
                        this.hideform()
                        this.formData = {}
                        this.formMessage = ''
                        this.isSending = false
                    }, 2275)
                })
        },
        toogleNavBarStatus() {
            const st = document.documentElement.scrollTop

            if (!document.querySelector('.hero_block')) {
                return
            }

            if (st >= 75) {
                document.querySelector('.hero_block').classList.add('remove_margin_top')
            } else {
                document.querySelector('.hero_block').classList.remove('remove_margin_top')
            }
        },
    },

    watch: {
        pageIsLoaded: {
            handler(pageIsLoaded) {
                if (pageIsLoaded) {
                    // Page loaded with Data
                    setTimeout(() => {
                        // this.teamCarousel = new Flickity(document.querySelector('.main-carousel'), {
                        //     wrapAround: true,
                        //     imagesLoaded: true,
                        //     prevNextButtons: true,
                        //     pageDots: false,
                        // })

                        this.toogleNavBarStatus()
                        window.addEventListener('scroll', () => {
                            this.toogleNavBarStatus()
                        })
                    }, 1055)
                }
            },
            immediate: true,
        },
    },

    computed: {
        pageIsLoaded() {
            return this.contentIsLoaded && this.globalsIsLoaded
        },
        ...mapState(['contentIsLoaded', 'globalsIsLoaded']),
        ...mapGetters(['seo', 'content', 'globals']),
    },
}
</script>
